<template>
	<div class="list">
		<Modal v-model="modal1" title="添加退货原因" @on-ok="ok" @on-cancel="cancel">
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="90">
				<FormItem label="原因类型:" prop="name">
					<Input v-model="formValidate.name" placeholder="请输入..."></Input>
				</FormItem>
				<FormItem label="是否启用:">
					<el-switch v-model="formValidate.sel" active-color="#13ce66" inactive-color="#ff4949">
					</el-switch>
				</FormItem>
			</Form>
		</Modal>
		<Card style='margin-left: 200px'>
			<div style="height: 600px;">
				<div class="titles">数据列表</div>
				<div class="title">
					<div class="titleText">
						数据列表
					</div>
					<div class="titletop" @click="modal1 = true">
						添加
					</div>
				</div>
				<div class="content">
					<div id="contents">
						<div class="cont">
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==1">
									<td>
										{{item2.rname}}
									</td>
									<td @click="cut(item2.rname, item2.is_start, item2.orid)">
										<el-switch v-model="item2.is_start==1" active-color="#13ce66" inactive-color="#ff4949">
										</el-switch>
									</td>
									<td>{{item2.utime}}</td>
									<td class="oper">
										<div class="stte" @click="compile(item2.rname, item2.is_start, item2.orid)">编辑</div>
										<div class="stte" @click="close(item2.orid)">删除</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Card>
	</div>
</template>

<script>
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			},
		},
		data() {
			return {
				formValidate: {
					name: '',
					sel: true
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入原因类型！！！',
						trigger: 'blur'
					}]
				},
				id: false,
				social: [],
				dialogVisible: false,
				imgUrl: '',
				modal1: false,
				id: null,
				SHlist: [],
				state: null
			}
		},
		created() {
			this.imgUrl = this.$request.imgUrl2() + 'uploads/';
		},
		components: {

		},
		methods: {
			ok() {
				let token = this.$storage.getLocal('token');
				if (this.id) {
					this.$refs['formValidate'].validate((valid) => {
						if (valid) {
							this.$request.orderRefundGoodsSet({
								token,
								type: this.$storage.getLocal('type'),
								storeid: '',
								rname: this.formValidate.name,
								is_start: this.formValidate.sel == true ? 1 : 2,
								orid: this.id
							}).then(res => {
								if (res.code == 0) {
									this.$emit("update", 'ok');
									this.$Message.success('修改成功!');
									this.$refs['formValidate'].resetFields();
									this.id = false;
								} else {
									this.$emit("update", 'ok');
									this.$notify({
										title: '失败',
										message: res.msg,
										type: 'warning'
									});
								}
							})
						} else {
							this.$Message.error('必填没有填!');
						}
					})
				} else {
					this.$refs['formValidate'].validate((valid) => {
						if (valid) {
							this.$request.orderRefundGoodsSet({
								token,
								type: this.$storage.getLocal('type'),
								storeid: '',
								rname: this.formValidate.name,
								is_start: this.formValidate.sel == true ? 1 : 2,
								orid: ''
							}).then(res => {
								if (res.code == 0) {
									this.$emit("update", 'ok');
									this.$Message.success('添加成功!');
									this.$refs['formValidate'].resetFields();
								} else {
									this.$emit("update", 'ok');
									this.$notify({
										title: '失败',
										message: res.msg,
										type: 'warning'
									});
								}
							})
						} else {
							this.$Message.error('必填没有填!');
						}
					})
				}

			},
			cancel() {
				// this.$Message.info('Clicked cancel');
				this.$refs['formValidate'].resetFields();
				this.id = false;
			},
			cut(name, is_start, id){
				let token = this.$storage.getLocal('token');
				this.$request.orderRefundGoodsSet({
					token,
					type: this.$storage.getLocal('type'),
					storeid: '',
					rname: name,
					is_start: is_start == true ? 2 : 1,
					orid: id
				}).then(res => {
					if (res.code == 0) {
						this.$emit("update", 'ok');
						if(is_start == 2){
							this.$Message.success('启用成功!');
						}else{
							this.$Message.warning('关闭成功!');
						}
						this.$refs['formValidate'].resetFields();
					} else {
						this.$emit("update", 'ok');
						this.$notify({
							title: '失败',
							message: res.msg,
							type: 'warning'
						});
					}
				})
			},
			compile(name, sel, id) {
				this.formValidate.name = name;
				this.formValidate.sel = sel == 1 ? true : false;
				this.modal1 = true;
				this.id = id
				console.log(id)
			},

			close(id) { //删除
				this.$confirm('此操作将删除此条退货原因,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					this.$request.orderRefundGoodsDel({
						token,
						orid: id
					}).then(res => {
						if (res.code == 0) {
							this.$emit("update", 'ok');
							this.$Message.success('删除成功!');
						} else {
							this.$emit("update", 'ok');
							this.$notify({
								title: '失败',
								message: res.msg,
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},

		}
	}
</script>

<style scoped="scoped">
	.titletop {
		cursor: pointer;
		width: 2rem;
		height: 1rem;
		text-align: center;
		line-height: 1rem;
		margin-top: 0.15rem;
		background-color: #ccc;
		border: 1px solid #2C3E50;
	}

	.title {
		padding: 0 1rem;
		width: 100%;
		background: #EEEEEE;
		display: flex;
		height: 0.8rem;
		line-height: 0.8rem;
		justify-content: space-between;
	}

	img {
		width: 20px;
	}

	table {
		width: 100%;
		background: #eeeeee;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.titNa {
		text-align: center;
	}

	.conBo {
		text-align: center;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}

	.shenhe {
		color: #42B983;
	}
</style>
