<template>
	<div class="reimburse">
		<frame :top1="top1" :top2="top2">
			<lists :titles='titles' :flag='flag' :contentC='datas' @update='refresh'></lists>
			<page :num='num'></page>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import search from './componReimburse/Search.vue';
	import lists from './componCause/List.vue';
	import tabbar from './componReimburse/TabbarList.vue';
	import page from '../commodity/componList/Page.vue';
	export default {
		name: 'reimburse',
		data() {
			return {
				top1: '5-7',
				top2: ['5'],
				titles: ['原因类型', '是否启用', '添加时间', '操作'],
				flag: 1,
				datas: null,
				num: 100
			}
		},
		components: {
			frame,
			page,
			lists
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.orderRefundGoodsSel({
				token,
				type: this.$storage.getLocal('type'),
				page: 1,
				limit: 10,
			}).then(res => {
				if (res.code == 0) {
					this.datas = res.data.data
					this.num = res.data.cunt
				}
			})
		},
		methods: {
			refresh(){
				let token = this.$storage.getLocal('token');
				this.$request.orderRefundGoodsSel({
					token,
					type: this.$storage.getLocal('type'),
					page: 1,
					limit: 10,
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.num = res.data.cunt
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
</style>
